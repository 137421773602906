.taskCard {
    border: 1px solid black;
    border-radius: 12px;
    .stared{ font-weight: 800;}
    .title {
        font-size: 260%;
        font-family: serif;
        padding: 1ex;
    }
    .publication {
        font-family: sans-serif;
        font-size: 60%;
        /* float: right; */
    }
    .publishedAt{
        font-family: monospace;
        /* float: left; */
    }
    .page{
        text-decoration: underline;
        font-size: 110%;
    }
    .subject {
        border: 2px solid blue; 
        border-radius: 13px;
        margin-right: 7px;
        padding: .5ex;
    }
}

@media only screen and (max-width: 900px) {
    .taskCard{
        min-height: 80vh;
        .page{
            margin-top:2ex;
            display: block;
        }
    }
    /* .mobile-100 {
        height: 100vh
    } */
}